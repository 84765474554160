@media (max-width: 767.98px) {
  .contact-text {
    width: 100% !important;

    .contact-title-wrapper {
      justify-content: center;

      .contact-title {
        width: min-content;
      }
    }
  }
}
