@font-face {
    font-family: "Stole";   /*Can be any text*/
    src: local("staravenue"),
    url("./fonts/staravenue.ttf") format("truetype");
}

body {
    background: #393E46;
    color: #fff;
    font-weight: 400;
    font-size: 1em;
    font-family: 'Stole', sans-serif;
}

*, *:after, *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.clearfix:before, .clearfix:after {
    display: table;
    content: '';
}

.clearfix:after {
    clear: both;
}

.grid {
    position: relative;
    clear: both;
    margin: 0 auto;
    padding: 1em 0 1em;
    max-width: 1200px;
    list-style: none;
    text-align: center;
}


.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: auto;
    background: #3085a3;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 0.5em;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
}

.grid figure figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 68.5%;
    align-items: center;
    text-align: center;
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
    background: #393e46;
}

figure.effect-ming img {
    opacity: 0.9;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    border: 2px solid #f7f7f7;
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale3d(1.4, 1.4, 1);
    transform: scale3d(1.4, 1.4, 1);
}


figure.effect-ming p {
    padding: 1.5em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    margin: 0;
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    margin: 0;
}

figure.effect-ming:hover figcaption {
    background-color: rgba(58, 52, 42, 0);
}

figure.effect-ming:hover img {
    opacity: 0.2;
    filter: grayscale(1);
}

figure.effect-ming-mobile figcaption::before,
figure.effect-ming-mobile p {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    margin: 0;
}

figure.effect-ming-mobile figcaption {
    background-color: rgba(58, 52, 42, 0);
}

figure.effect-ming-mobile img {
    opacity: 0.2;
    filter: grayscale(1);
}

@media (max-width: 767.98px) {
    .home-mobile{
        display: block !important;
    }

    .home-desktop{
        display: none;
    }
}

@media (max-width: 767.98px) {
    .project-details-mobile{
        display: block !important;
    }

    .project-details-desktop{
        display: none;
    }
}
