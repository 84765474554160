.filter ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}

.filter nav li {
  display: inline-block;
  line-height: 50px;
  transition: .5s linear;
}

.filter nav span {
  display: block;
  position: relative;
  color: #ffffff;
  letter-spacing: 0.5px;
}

.filter nav span:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 15px;
  background: #ffffff;
  transition: width .2s linear;
}

.filter nav span:hover:after {
  width: 100%;
}

.filter nav span:hover {
  color: #ffffff;
}

.filter nav span.active:after {
  width: 100%;
}
