.about span{
  font-size: 15px;
  letter-spacing: 1px;
  text-align: justify;
}

.about-picture{
  border-radius: 50%;
  width: 333px;
  height: 333px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.about-text{
  padding: 0 30px 0 64px;

  @media (max-width: 767.98px) {
    padding: 0 25px;
  }
}