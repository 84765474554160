.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: auto;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.swiper-slide img {
    width: auto;
    height: 75vh;
}

.swiper-button-next {
    color: white;
}

.swiper-button-prev {
    color: white;
}
.swiper-slide {
    opacity: 0.1;
}

.swiper-slide-active {
    opacity: 1;
}
