.main-header .logo-text {
    font-size: xx-large;
    color: #ffffff;
    text-decoration: none;
}

.main-header.navbar span {
    color: #ffffff;
    cursor: pointer;
}

.main-header .navbar-brand {
    display: inline-block;
    line-height: 50px;
    position: relative;
    color: #ffffff;
    text-transform: uppercase;
    transition: .5s linear;
}

@media (max-width: 767.98px) {
    .main-header .navbar-brand:after {
        bottom: 7px !important;
    }
}

.main-header .navbar-brand:after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 15px;
    background: #ffffff;
    transition: width .2s linear;
}

.main-header .mobile-filter .filter-span {
    width: min-content;
    display: block;
    line-height: 25px;
    position: relative;
    color: #ffffff;
    text-transform: uppercase;
    transition: .5s linear;
}

.main-header .mobile-filter .filter-span:after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 6px;
    background: #ffffff;
    transition: width .2s linear;
}

@media (min-width: 768px) {
    .main-header .navbar-brand:hover:after {
        width: 100%;
    }
}

.main-header .navbar-brand:hover {
    color: #ffffff;
}

.main-header .navbar-brand.active:after {
    width: 100%;
}

.main-header .mobile-filter .filter-span.active:after {
    width: 100%;
}
/*======================================= BURGER MENU =========================================================*/

*,
*:before,
*:after {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-sizing: border-box;
}


.burger-check {
    display: none;
}

.burger {
    position: relative;
    float: left;
    width: 60px;
    height: 55px;
    cursor: pointer;
    margin-bottom: 10px;
}

.burger:after {
    content: '';
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 13px;
}


.burger span,
.burger span:before,
.burger span:after {
    position: absolute;
    left: 10px;
    top: 22px;
    width: 40px;
    height: 4px;
    background: white;
    border-radius: 0.2em;
}


.burger span:before,
.burger span:after {
    content: '';
    left: 0;
}


.burger span:before {
    top: -8px;
}


.burger span:after {
    top: 8px;
}


.burger-check:checked + .burger:after {
    content: '';
}

.burger-check:checked + .burger span {
    height: 0;
    width: 0;
}

.burger-check:checked + .burger span:before,
.burger-check:checked + .burger span:after {
    top: 6px;
}

.burger-check:checked + .burger span:before {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg);
}

.burger-check:checked + .burger span:after {
    -webkit-transform: rotate(-225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(-225deg);
}

span.boo {
    width: auto;
    margin-top: 0;
    line-height: 60px;
    height: 0;
    overflow: hidden;
}

#main-nav-test:checked ~ .boo {
    height: 50px;
}

.a-header {
    position: relative;
    float: left;
    width: 100%;
    margin: 0;
    min-height: 50px;
    overflow: hidden;
}

.a-header ul {
    position: relative;
    float: left;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.a-header ul a {
    position: relative;
    float: left;
    width: 100%;
    height: 0;
    line-height: 50px;
    padding: 0 15px;
    text-decoration: none;
    overflow: hidden;
    color: #333;
    text-align: left;
}

.a-header ul a:hover,
.a-header ul a:active,
.a-header ul a:focus,
.a-header ul a:visited {
    text-decoration: none;
    background: #fff;
    border-bottom-color: #08c;
}

#main-nav:checked ~ ul a {
    height: 50px;
}

.collapsed {
    margin-bottom: 0;
}

.mobile-line-height {
    line-height: unset !important;
}